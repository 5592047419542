class genericFilterPage extends Component {

    static name() {
        return "genericFilterPage";
    }

    static componentName() {
        return "genericFilterPage";
    }

    data() {
        return {
            alias:{
                Categories:null,
                Items:null,
                ExpresionFilter:null
            }
        };
    }

    getWatch() {
        return {
            '$store.state.appState':'createFilter',
        };
    }

    getComputed() {
        return {
            aliasCode: function(){
                return this.$route.params.aliasCode;
            }
        };
    }

    mounted() {
        return async function () {
            if(this.$store.getters.getAppState=='ready')
                this.createFilter();
        };
    }

    getMethods() {
        return {
            createFilter:this.createFilter,
            loadCategories: this.loadCategories,
            updateCategory: this.updateCategory,
            loadItems:this.loadItems,
            selectCategory: this.selectCategory
        };
    }

    async createFilter(){
        let aliasRecord = await this.$store.dispatch('loadAlias',this.aliasCode);
        if(aliasRecord) {
            this.alias = aliasRecord.fields;
            //console.log("alias Code",aliasRecord,this.alias);
            let objectFilter = {};
            objectFilter.name = `alias-filter-${this.aliasCode}`;
            objectFilter.type = 'genericFilter';
            objectFilter.label = this.aliasCode;
            objectFilter.value = this.aliasCode;
            objectFilter.code = this.aliasCode;
            objectFilter.condition = null;
            objectFilter.result = true;
            if (this.alias.Categories)
                objectFilter.condition = `(['${this.alias.Categories.split(',').join("','")}'].some(r=>x.Classification.split(',').indexOf(r) >= 0))`;
            if (this.alias.Items) {
                if (objectFilter.condition) {
                    objectFilter.condition = objectFilter.condition + ` || (['${this.alias.Items.split(',').join("','")}'].indexOf(x.Code)>= 0)`;
                } else {
                    objectFilter.condition = `(['${this.alias.Items.split(',').join("','")}'].indexOf(x.Code)>= 0)`;
                }
            }
            if (this.alias.ExpresionFilter) {
                if (objectFilter.condition) {
                    objectFilter.condition = objectFilter.condition + ` || (${this.alias.ExpresionFilter})`;
                } else
                    objectFilter.condition = this.alias.ExpresionFilter;
            }

            if (objectFilter.condition)
                this.$store.dispatch('addGlobalFilter', objectFilter);
            return objectFilter;
        }
        else {
            // eslint-disable-next-line no-console
            console.log('Alias not Found');
        }
    }

    loadCategories(root = null, lvl = 0) {
        this.$store.dispatch('loadCategories', root, lvl);
    }

    updateCategory(category) {
        this.$store.dispatch('updateCategory', category);
    }

    loadItems(category = null) {
        this.$store.dispatch('loadItems', category);
    }

    selectCategory(category) {
        if (category) {
            this.loadItems(category.code);
            this.$store.dispatch('updateCategory', category);
        }
        else {
            this.$store.dispatch('updateCategory', {children: []});
            this.loadItems();
        }
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer">
                      <div class="section-main">
                            <div class="row">
                              <div class="col-sm-4 col-md-3 col-xs-12 mb-2 mb-sm-0 pt-0 px-2 pb-2 p-sm-2 filters-section">
                                  <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  <categorySectionComponent :categories=false></categorySectionComponent>
                              </div>
                              <div class="col-sm-8 col-md-9 col-xs-12 pt-2 pt-sm-0 px-0 px-md-3">
                                  <div class="col-12">
                                    <itemsSectionComponent :items="$store.items" :items_in_car="$store.items_in_car" :cat_history="$store.cat_history"></itemsSectionComponent>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

genericFilterPage.registerComponent();